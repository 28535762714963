<template>
   <!-- Need to add height inherit because Vue 2 don't support multiple root ele -->
   <div style="height: inherit">
	  <div
		  :class="{'show': mqShallShowLeftSidebar}"
		  class="body-content-overlay"
		  @click="mqShallShowLeftSidebar = false"
	  />
	  <div class="todo-app-list">

		 <!-- App Searchbar Header -->
		 <div class="app-fixed-search d-flex align-items-center">

			<!-- Toggler -->
			<div class="sidebar-toggle d-block d-lg-none ml-1">
			   <feather-icon
				   class="cursor-pointer"
				   icon="MenuIcon"
				   size="21"
				   @click="mqShallShowLeftSidebar = true"
			   />
			</div>

			<!-- Searchbar -->
			<div class="d-flex align-content-center justify-content-between w-100">
			   <b-input-group class="input-group-merge">
				  <b-input-group-prepend is-text>
					 <feather-icon
						 class="text-muted"
						 icon="SearchIcon"
					 />
				  </b-input-group-prepend>
				  <input
					  v-debounce:800ms="updateRouteQuery"
					  :placeholder="$t('todos.search-tag')"
					  :style="{'width':'90%'}"
					  :value="searchQuery"
				  />
			   </b-input-group>
			</div>

			<!-- Dropdown -->
			<div class="dropdown">
			   <b-dropdown
				   no-caret
				   right
				   toggle-class="p-0 mr-1"
				   variant="link"
			   >
				  <template #button-content>
					 <feather-icon
						 class="align-middle text-body"
						 icon="MoreVerticalIcon"
						 size="16"
					 />
				  </template>
				  <b-dropdown-item @click="resetSortAndNavigate">
					 {{ $t('generic.reset-sort') }}
				  </b-dropdown-item>
				  <b-dropdown-item :to="{ name: $route.name, query: { ...$route.query, sort: 'asc' } }">
					 {{ $t('generic.a-to-z') }}
				  </b-dropdown-item>
				  <b-dropdown-item :to="{ name: $route.name, query: { ...$route.query, sort: 'desc' } }">
					 {{ $t('generic.z-to-a') }}
				  </b-dropdown-item>
				  <b-dropdown-item :to="{ name: $route.name, query: { ...$route.query, sort: 'assignee' } }">
					 {{ $t('generic.by-partner') }}
				  </b-dropdown-item>
				  <b-dropdown-item :to="{ name: $route.name, query: { ...$route.query, sort: 'due-date' } }">
					 {{ $t('generic.by-end-date') }}
				  </b-dropdown-item>
			   </b-dropdown>
			</div>
		 </div>

		 <!-- Todo List -->
		 <vue-perfect-scrollbar
			 :settings="perfectScrollbarSettings"
			 class="todo-task-list-wrapper list-group scroll-area"
		 >
			<draggable
				v-model="tasks"
				class="todo-task-list media-list"
				handle=".draggable-task-handle"
				tag="ul"
				@change="onDrag"
			>
			   <li
				   v-for="(task,idx) in tasks"
				   :key="idx"
				   :class="{ 'completed': task.completed }"
				   class="todo-item"
				   @click="handleTaskClick(task)"
			   >
				  <feather-icon
					  class="draggable-task-handle d-inline"
					  icon="MoreVerticalIcon"
				  />
				  <div class="todo-title-wrapper">
					 <div class="todo-title-area">
						<div class="title-wrapper">
						   <b-form-checkbox
							   :checked="task.completed?true:false"
							   @change="updateTaskIsCompleted(task)"
							   @click.native.stop
						   />
						   <span class="todo-title">{{ task.title }}</span>
						</div>
					 </div>
					 <div class="todo-item-action">
						<div class="badge-wrapper mr-1">
						   <b-badge
							   v-for="tag in task.tags"
							   :key="tag.id"
							   :style="{backgroundColor: setRgba(tag.color)}"
							   class="text-capitalize"
							   pill
						   >

							  <span :style="{color: tag.color}">
							  {{ tag.name }}
							  </span>
						   </b-badge>
						</div>
						<small class="text-nowrap text-muted mr-1">{{
							  formatDate(task.dueDate, {
								 month: 'short',
								 day: 'numeric'
							  })
																   }}</small>
						<b-avatar
							v-if="task.partner !==null"
							:src="task.partner.photo"
							:text="avatarText(fullName(task.partner))"
							:variant="getColor(task.partner.color)"
							size="32"
						/>


					 </div>
				  </div>

			   </li>
			</draggable>
			<div
				:class="{'show': !tasks.length}"
				class="no-results"
			>
			   <h5>{{ $t('errors.no-entries-found') }}</h5>
			</div>
		 </vue-perfect-scrollbar>
	  </div>

	  <!-- Task Handler -->
	  <todo-task-handler-sidebar
		  v-model="isTaskHandlerSidebarActive"
		  :clear-task-data="clearTaskData"
		  :partners="partners"
		  :task="task"
		  :task-tags="taskTags"
		  @remove-task="removeTask(task)"
		  @add-task="addTask"
		  @update-task="updateTask"
	  />

	  <!-- Sidebar -->
	  <portal to="content-renderer-sidebar-left">
		 <todo-left-sidebar
			 :class="{'show': mqShallShowLeftSidebar}"
			 :is-task-handler-sidebar-active.sync="isTaskHandlerSidebarActive"
			 :task-tags="taskTags"
			 @close-left-sidebar="mqShallShowLeftSidebar = false"
		 />
	  </portal>
   </div>
</template>

<script>
import store from '@/store'
import {computed, ref, watch,} from '@vue/composition-api'
import {
   BAvatar,
   BBadge,
   BDropdown,
   BDropdownItem,
   BFormCheckbox,
   BFormInput,
   BInputGroup,
   BInputGroupPrepend,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import draggable from 'vuedraggable'
import {avatarText, formatDate} from '@core/utils/filter'
import {useRouter} from '@core/utils/utils'
import {useResponsiveAppLeftSidebarVisibility} from '@core/comp-functions/ui/app'
import TodoLeftSidebar from './TodoLeftSidebar.vue'
import TodoTaskHandlerSidebar from './TodoTaskHandlerSidebar.vue'
import axiosIns from "@/libs/axios";
import _ from "lodash";
import moment from "moment";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import caldoSettings from "@/caldoSettings";

export default {
   components: {
	  BFormInput,
	  BInputGroup,
	  BInputGroupPrepend,
	  BDropdown,
	  BDropdownItem,
	  BFormCheckbox,
	  BBadge,
	  BAvatar,
	  draggable,
	  VuePerfectScrollbar,

	  // App SFC
	  TodoLeftSidebar,
	  TodoTaskHandlerSidebar,
   },
   async created() {
	  window.swall = this.$swal;
	  window.toast = this.$toast;

	  // await this.$store.dispatch("fetchTodos");
   },

   async mounted() {
   },

   setup() {
	  const TODO_APP_STORE_MODULE_NAME = 'app-todo'


	  // // Register module
	  // if (!store.hasModule(TODO_APP_STORE_MODULE_NAME)) store.registerModule(TODO_APP_STORE_MODULE_NAME, todoStoreModule)
	  //
	  // // UnRegister on leave
	  // onUnmounted(() => {
	  //  if (store.hasModule(TODO_APP_STORE_MODULE_NAME)) store.unregisterModule(TODO_APP_STORE_MODULE_NAME)
	  //
	  //
	  // })


	  const {route, router} = useRouter()
	  const routeSortBy = computed(() => route.value.query.sort)
	  const routeQuery = computed(() => route.value.query.q)
	  const routeParams = computed(() => route.value.params)
	  watch(routeParams, async () => {
		 // eslint-disable-next-line no-use-before-define


		 await fetchTasks()
	  })


	  const tasks = ref([])
	  const partners = ref([])

	  const sortOptions = [
		 'latest',
		 'asc',
		 'desc',
		 'assignee',
		 'due-date',
	  ]
	  const sortBy = ref(routeSortBy.value)
	  watch(routeSortBy, val => {
		 if (sortOptions.includes(val)) sortBy.value = val
		 else sortBy.value = val

	  })
	  const resetSortAndNavigate = () => {
		 let query = Object.assign({}, router.query);
		 delete query.param;
		 router.replace({query});


	  }

	  const blankTask = {
		 id: null,
		 title: '',
		 dueDate: new Date(),
		 description: '',
		 assignee: null,
		 tags: [],
		 completed: false,
		 isDeleted: false,
		 isImportant: false,
	  }

	  function fullName(name, surname) {
		 return avatarText(name?.name, surname?.surname)
	  }

	  function getColor(color) {
		 return caldoSettings.getColor(color?.color)
	  }

	  const task = ref(JSON.parse(JSON.stringify(blankTask)))

	  const clearTaskData = () => {
		 task.value = JSON.parse(JSON.stringify(blankTask))
	  }


	  const onDrag = async val => {
		 let ids = [];
		 let listOrders = [];

		 tasks.value.map((el, idx) => {
			ids.push(el.id)
			listOrders.push(idx)
		 })

		 await axiosIns.put(`/todo-order`, {
			ids,
			listOrders
		 })
		 notify(`Ενημερώθηκε`)
	  }


	  const addTask = val => {


		 const todo = _.omitBy({
			"title": val.title,
			"partnerId": val.partners ?? val.partner,
			"dueDate": moment(val.dueDate).format("YYYY-MM-DD HH:mm"),
			"tags": val.tags.map(el => el.id),
			"description": val.description
		 }, (v) => _.isUndefined(v) || _.isNull(v) || v === '');

		 axiosIns.post(`/todo`, todo).then(response => {
			fetchTasks()
			notify(`Αποθήκευση`)
		 })


	  }
	  const removeTask = async (task) => {


		 const {isConfirmed} = await window.swall({
			title: 'Είστε σίγουρος;',
			text: "Δεν θα μπορείτε να το επαναφέρετε!",
			type: 'warning',
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#7367f0',
			cancelButtonColor: '#82868b',
			confirmButtonText: 'Διαγραφή',
			cancelButtonText: 'Ακύρωση',
			reverseButtons: true,
			buttonsStyling: true,
			animation: true
		 });
		 if (isConfirmed) {
			await axiosIns.delete(`/todo`, {
			   params: {
				  ids: [task.id]
			   }
			})
			await fetchTasks()
		 }

	  }
	  const updateTask = taskData => {
		 let task = _.omitBy({
			"title": taskData.title,
			"partnerId": taskData.partner,
			"dueDate": moment(taskData.dueDate).format("YYYY-MM-DD HH:mm"),
			"tags": taskData.tags.map(el => el.id),
			"completed": taskData.completed,
			"important": taskData.important,
			"description": taskData.description
		 }, (v) => _.isUndefined(v) || _.isNull(v) || v === '');

		 _.mapValues(task, v => {
			if (task.dueDate === null) {
			   delete task.dueDate;
			}

			if (typeof task.partnerId == "object") {
			   delete task.partnerId;
			}

		 });

		 const response = axiosIns.put(`/todo/${taskData.id}`, task).then(response => {
			fetchTasks()
			notify(`Ενημερώθηκε`)
		 })


	  }

	  const perfectScrollbarSettings = {
		 maxScrollbarLength: 150,
	  }

	  const isTaskHandlerSidebarActive = ref(false)


	  const taskTags = computed(() => {
		 return store.getters.getTags.map(tag => {
			return {
			   name: capitalize(tag.name),
			   color: tag.color,
			   id: tag.id,
			   route: {
				  name: "apps-todo-tag",
				  params: {tag: tag.id}
			   }
			}
		 })

	  })


	  const capitalize = (s) => {
		 if (typeof s !== 'string') return ''
		 return s.charAt(0).toUpperCase() + s.slice(1)
	  }

	  const resolveTagVariant = tag => {
		 if (tag === 'team') return 'primary'
		 if (tag === 'low') return 'success'
		 if (tag === 'medium') return 'warning'
		 if (tag === 'high') return 'danger'
		 if (tag === 'update') return 'info'
		 return 'primary'
	  }

	  const resolveAvatarVariant = tags => {
		 if (tags.includes('high')) return 'primary'
		 if (tags.includes('medium')) return 'warning'
		 if (tags.includes('low')) return 'success'
		 if (tags.includes('update')) return 'danger'
		 if (tags.includes('team')) return 'info'
		 return 'primary'
	  }

	  const resolveAvatarColor = num => {
		 const color = ['primary', 'warning', 'success', 'danger', 'info']

		 return color[num];
	  }
	  const notify = (title, variant = 'success', text = '') => {
		 window.toast({
			component: ToastificationContent,
			position: 'top-right',
			props: {
			   title: title,
			   icon: 'CheckIcon',
			   variant: variant,
			   text: text,
			},
		 })
	  }


	  // Search Query
	  const searchQuery = ref(routeQuery.value)
	  watch(routeQuery, val => {
		 searchQuery.value = val
	  })

	  const updateRouteQuery = async val => {
		 searchQuery.value = val
		 await fetchTasks();

	  }


	  function checkStatusTask(filterParams) {
		 const filter = router.currentRoute.params.filter

		 if (filter !== undefined) {
			if (filter === 'important') {
			   filterParams.important = 1
			   delete filterParams.completed
			} else if (filter === 'completed') {
			   filterParams.completed = 1
			   delete filterParams.important
			}

		 }

		 if (filter === undefined) {
			delete filterParams.completed
			delete filterParams.important
		 }
	  }

	  function setRgba(color) {
		 return hexToRgbA(color);
	  }

	  function checkSortTask(filterParams) {
		 if (filterParams.sortBy === 'due-date') {
			filterParams.orderBy = ['dueDate'];
			filterParams.sortBy = 'asc'
		 }
		 if (filterParams.sortBy === 'assignee') {
			filterParams.orderBy = ['partner'];
			filterParams.sortBy = 'desc'
		 }
	  }

	  function hexToRgbA(hex) {
		 var c;
		 if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
			c = hex.substring(1).split('');
			if (c.length == 3) {
			   c = [c[0], c[0], c[1], c[1], c[2], c[2]];
			}
			c = '0x' + c.join('');
			return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',0.12)';
		 }
		 throw new Error('Bad Hex');
	  }

	  function checkTagTask(filterParams) {
		 if (router.currentRoute.params.tag) {
			filterParams['tagId'] = router.currentRoute.params.tag
		 }
	  }

	  const fetchTasks = async () => {

		 let filterParams = _.omitBy({
			searchKey: searchQuery.value,
			rpp: 100,
			completed: 0,
			important: 0,
			orderBy: ["listOrder"],
			sortBy: routeSortBy.value
		 }, (v) => _.isUndefined(v) || _.isNull(v) || v === '');


		 checkStatusTask(filterParams);
		 checkSortTask(filterParams);
		 checkTagTask(filterParams);


		 if (routeSortBy.value === 'asc' || routeSortBy.value === 'desc') {
			filterParams.orderBy = ['title'];

		 }


		 const response = axiosIns.get(`/todo`, {
			params: filterParams
		 }).then(response => {

			tasks.value = response.data.data
		 })

		 const {data} = await axiosIns.get(`/partner`, {
			params: {
			   "rpp": 100,
			   "orderBy": ["surname", "name", "email"],
			   "sortBy": "asc"
			}
		 })


		 partners.value = data.data
	  }

	  fetchTasks()

	  const handleTaskClick = taskData => {
		 task.value = taskData
		 isTaskHandlerSidebarActive.value = true
	  }

	  // Single Task completed update
	  const updateTaskIsCompleted = taskData => {
		 // eslint-disable-next-line no-param-reassign
		 taskData.completed = !taskData.completed
		 updateTask(taskData)
	  }

	  const {mqShallShowLeftSidebar} = useResponsiveAppLeftSidebarVisibility()

	  return {
		 task,
		 onDrag,
		 tasks,
		 removeTask,
		 addTask,
		 updateTask,
		 clearTaskData,
		 taskTags,
		 partners,
		 setRgba,
		 searchQuery,
		 fullName,
		 fetchTasks,
		 perfectScrollbarSettings,
		 updateRouteQuery,
		 resetSortAndNavigate,
		 resolveAvatarColor,
		 // UI
		 getColor,
		 resolveTagVariant,
		 resolveAvatarVariant,
		 isTaskHandlerSidebarActive,

		 // Click Handler
		 handleTaskClick,

		 // Filters
		 formatDate,
		 avatarText,

		 // Single Task completed update
		 updateTaskIsCompleted,

		 // Left Sidebar Responsive
		 mqShallShowLeftSidebar,
	  }
   },
}
</script>

<style lang="scss"
	   scoped>
.draggable-task-handle {
   position: absolute;
   left: 8px;
   top: 50%;
   transform: translateY(-50%);
   visibility: hidden;
   cursor: move;

   .todo-task-list .todo-item:hover & {
	  visibility: visible;
   }
}
</style>

<style lang="scss">
*:focus {
   outline: none;
}


@import "~@core/scss/base/pages/app-todo.scss";
</style>
