<template>
   <div class="sidebar-left">
	  <div class="sidebar">
		 <div class="sidebar-content todo-sidebar">
			<div class="todo-app-menu">
			   <div class="add-task">
				  <b-button
					  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
					  block
					  variant="primary"
					  @click="$emit('update:is-task-handler-sidebar-active', true); $emit('close-left-sidebar')"
				  >
					 {{ $t('generic.add') }}
				  </b-button>
			   </div>
			   <vue-perfect-scrollbar
				   :settings="perfectScrollbarSettings"
				   class="sidebar-menu-list scroll-area"
			   >
				  <!-- Filters -->
				  <b-list-group class="list-group-filters">
					 <b-list-group-item
						 v-for="filter in taskFilters"
						 :key="filter.title + $route.path"
						 :active="isDynamicRouteActive(filter.route)"
						 :to="filter.route"
						 @click="$emit('close-left-sidebar')"
					 >
						<feather-icon
							:icon="filter.icon"
							class="mr-75"
							size="18"
						/>
						<span class="align-text-bottom line-height-1">{{ $t("generic." + filter.title) }}</span>
					 </b-list-group-item>
				  </b-list-group>

				  <!-- Tags -->
				  <div class="mt-3 px-2">
					 <h6 class="section-label mb-1">
						{{ $t('generic.tags') }}
					 </h6>
				  </div>

				  <b-list-group class="list-group-labels">
					 <b-list-group-item
						 v-for="tag in taskTags"
						 :key="tag.name + $route.path"
						 :active="isDynamicRouteActive(tag.route)"
						 :to="tag.route"
						 @click="$emit('close-left-sidebar')"
					 >
                <span
					:style="{backgroundColor:tag.color}"
					class="bullet bullet-sm mr-1"
				/>
						<span>{{ tag.name }}</span>
					 </b-list-group-item>
				  </b-list-group>

			   </vue-perfect-scrollbar>
			</div>
		 </div>
	  </div>
   </div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import {BButton, BListGroup, BListGroupItem} from 'bootstrap-vue'
import {isDynamicRouteActive} from '@core/utils/utils'
import Ripple from 'vue-ripple-directive'
import {mapGetters} from "vuex";

export default {
   directives: {
	  Ripple,
   },
   components: {
	  BButton,
	  BListGroup,
	  BListGroupItem,
	  VuePerfectScrollbar,
   },
   props: {
	  taskTags: {
		 type: Array,
		 required: true,
	  },
   },
   computed: {
	  ...mapGetters(["getTags"])
   },


   async created() {
	  await this.$store.dispatch("fetchTodos");
   },
   setup() {
	  const perfectScrollbarSettings = {
		 maxScrollbarLength: 60,
	  }


	  const taskFilters = [
		 {title: 'tasks', icon: 'MailIcon', route: {name: 'todo'}},
		 {title: 'stared', icon: 'StarIcon', route: {name: 'apps-todo-filter', params: {filter: 'important'}}},
		 {title: 'completed-2', icon: 'CheckIcon', route: {name: 'apps-todo-filter', params: {filter: 'completed'}}},
		 // {title: 'Deleted', icon: 'TrashIcon', route: {name: 'apps-todo-filter', params: {filter: 'deleted'}}},
	  ]

	  return {
		 perfectScrollbarSettings,
		 taskFilters,
		 isDynamicRouteActive,
	  }
   },
}
</script>

<style>

</style>
