<template>
   <div>
	  <b-sidebar
		  id="sidebar-task-handler"
		  :visible="isTaskHandlerSidebarActive"
		  backdrop
		  bg-variant="white"
		  no-header
		  right
		  shadow
		  sidebar-class="sidebar-lg"
		  @change="(val) => $emit('update:is-task-handler-sidebar-active', val)"
		  @hidden="clearForm"
	  >
		 <template #default="{ hide }">
			<!-- Header -->
			<div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
			   <b-button
				   v-if="taskLocal.id"
				   :variant="taskLocal.completed ? 'outline-success' : 'outline-secondary'"
				   size="sm"
				   @click="taskLocal.completed = !taskLocal.completed; $emit('update-task',taskLocal);"
			   >
				  {{ taskLocal.completed ? $t('generic.completed-1') : $t('todos.markcomplete') }}
			   </b-button>
			   <h5
				   v-else
				   class="mb-0"
			   >
				  {{ $t('todos.new-task') }}
			   </h5>
			   <div>
				  <feather-icon
					  v-show="taskLocal.id"
					  class="cursor-pointer"
					  icon="TrashIcon"
					  @click="$emit('remove-task'); hide();"
				  />
				  <feather-icon
					  :class="{ 'text-warning': taskLocal.important }"
					  class="ml-1 cursor-pointer"
					  icon="StarIcon"
					  size="16"
					  @click="taskLocal.important = !taskLocal.important;$emit('update-task',taskLocal);"
				  />
				  <feather-icon
					  class="ml-1 cursor-pointer"
					  icon="XIcon"
					  size="16"
					  @click="hide"
				  />
			   </div>
			</div>

			<!-- Body -->
			<validation-observer
				ref="refFormObserver"
				#default="{ handleSubmit , invalid }"
			>

			   <!-- Form -->
			   <b-form
				   class="p-2"
				   @submit.prevent="handleSubmit(onSubmit)"
				   @reset.prevent="resetForm"
			   >

				  <!-- Title -->
				  <validation-provider
					  #default="validationContext"
					  name="Title"
					  rules="required"
				  >
					 <b-form-group
						 :label="$t('generic.title')"
						 class="required"
						 label-for="task-title"
					 >
						<b-form-input
							id="task-title"
							v-model="taskLocal.title"
							:state="getValidationState(validationContext)"
							autofocus
							trim
						/>

						<b-form-invalid-feedback>
						   {{ validationContext.errors[0] }}
						</b-form-invalid-feedback>
					 </b-form-group>
				  </validation-provider>

				  <!-- Assignee -->


				  <b-form-group
					  :label="$t('todos.partner')"
					  label-for="assignee"
				  >
					 <v-select
						 v-model="taskLocal.partner"
						 :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
						 :get-option-label="(option) => option.surname"
						 :options="partners"
						 :reduce="val => val.id"
						 class="assignee-selector"
						 input-id="assignee"
					 >
						<template v-slot:no-options>{{ $t('errors.no-matching') }}</template>
						<template #option="{ photo, name,surname ,color}">
						   <b-avatar
							   :src="photo"
							   :text="fullName(surname,name)"
							   :variant="getColor(color)"
							   size="26"
						   />
						   <span class="ml-50 d-inline-block align-middle">{{ surname }} {{ name }}</span>
						</template>

						<template #selected-option="{ photo, name,surname,color }">
						   <b-avatar
							   :src="photo"
							   :text="fullName(surname,name)"
							   :variant="getColor(color)"
							   size="26"
						   />

						   <span class="ml-50 d-inline-block align-middle">{{ surname }} {{ name }}</span>
						</template>
					 </v-select>
				  </b-form-group>


				  <!-- due Date -->
				  <validation-provider
					  #default="validationContext"
					  name="Due Date"
					  rules="required"
				  >

					 <b-form-group
						 :label="$t('todos.deadline')"
						 label-for="due-date"
					 >
						<!--						<flat-pickr-->
						<!--							v-model="taskLocal.dueDate"-->
						<!--							:config="config"-->
						<!--							class="form-control"-->
						<!--						/>-->
						<div class="w-100">
						   <date-picker
							   v-model="taskLocal.dueDate"
							   :show-time-header="true"
							   class="w-100"
							   format="DD-MM-YYYY HH:mm"
							   type="datetime"
							   valueType="YYYY-MM-DD HH:mm" />
						</div>

						<b-form-invalid-feedback :state="getValidationState(validationContext)">
						   {{ validationContext.errors[0] }}
						</b-form-invalid-feedback>
					 </b-form-group>
				  </validation-provider>

				  <!--Tag -->
				  <b-form-group
					  :label="$t('generic.tag')"
					  label-for="tag"
				  >
					 <v-select
						 v-model="taskLocal.tags"
						 :close-on-select="false"
						 :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
						 :get-option-label="(option) => option.name"
						 :options="taskTags"
						 input-id="tags"
						 multiple
					 >
						<template v-slot:no-options>{{ $t('errors.no-matching') }}</template>
					 </v-select>
				  </b-form-group>

				  <!-- Description -->
				  <b-form-group
					  :label="$t('contacts.notes')"
					  label-for="task-description"
				  >
					 <quill-editor
						 id="quil-content"
						 v-model="taskLocal.description"
						 :options="editorOption"
						 class="border-bottom-0"
						 style="height: 200px"
					 />
					 <div
						 id="quill-toolbar"
						 class="d-flex justify-content-end border-top-0"
					 >
						<!-- Add a bold button -->

					 </div>
				  </b-form-group>

				  <!-- Form Actions -->
				  <div class="d-flex mt-5 pt-2">
					 <b-button
						 v-ripple.400="'rgba(255, 255, 255, 0.15)'"
						 :disabled="invalid"
						 class="mr-2"
						 type="submit"
						 variant="primary"
					 >
						{{ taskLocal.id ? $t('generic.save') : $t('generic.add') }}
					 </b-button>
					 <!--              <b-button-->
					 <!--                v-ripple.400="'rgba(186, 191, 199, 0.15)'"-->
					 <!--                type="reset"-->
					 <!--                variant="outline-secondary"-->
					 <!--              >-->
					 <!--                Reset-->
					 <!--              </b-button>-->
				  </div>
			   </b-form>
			</validation-observer>
		 </template>
	  </b-sidebar>
   </div>
</template>

<script>
import {BAvatar, BButton, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BSidebar,} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import {extend, ValidationObserver, ValidationProvider} from 'vee-validate'
import {email, required, url} from '@validations'
import {avatarText} from '@core/utils/filter'
import formValidation from '@core/comp-functions/forms/form-validation'
import {toRefs} from '@vue/composition-api'
import {quillEditor} from 'vue-quill-editor'
import useTaskHandler from './useTaskHandler'
import caldoSettings from "@/caldoSettings";


extend('required', {
   ...required,
   message: 'Υποχρεωτικό πεδίο'

})
export default {
   components: {
	  // BSV
	  BButton,
	  BSidebar,
	  BForm,
	  BFormGroup,
	  BFormInput,
	  BAvatar,
	  BFormInvalidFeedback,

	  // 3rd party packages
	  vSelect,
	  quillEditor,

	  // Form Validation
	  ValidationProvider,
	  ValidationObserver,
   },
   directives: {
	  Ripple,
   },
   model: {
	  prop: 'isTaskHandlerSidebarActive',
	  event: 'update:is-task-handler-sidebar-active',
   },
   props: {
	  isTaskHandlerSidebarActive: {
		 type: Boolean,
		 required: true,
	  },
	  task: {
		 type: Object,
		 required: true,
	  },
	  partners: {
		 type: Array,
		 required: true,
	  },
	  taskTags: {
		 type: Array,
		 required: true,
	  },
	  clearTaskData: {
		 type: Function,
		 required: true,
	  },
   },
   data() {
	  return {
		 required,
		 email,
		 url,

	  }
   },
   setup(props, {emit}) {
	  const {
		 taskLocal,
		 resetTaskLocal,

		 // UI
		 assigneeOptions,
		 onSubmit,
		 taskTags,
		 tagOptions,
		 resolveAvatarVariant,
	  } = useTaskHandler(toRefs(props), emit)

	  function fullName(name, surname) {
		 return avatarText(name, surname)
	  }


	  function getColor(color) {
		 return caldoSettings.getColor(color)
	  }


	  const {
		 refFormObserver,
		 getValidationState,
		 resetForm,
		 clearForm,
	  } = formValidation(resetTaskLocal, props.clearTaskData)

	  const editorOption = {
		 modules: {},
		 placeholder: 'Eισάγετε κείμενο εδώ…',
	  }

	  return {
		 // Add New
		 taskLocal,
		 onSubmit,
		 assigneeOptions,
		 tagOptions,
		 // Form Validation
		 resetForm,
		 clearForm,
		 refFormObserver,
		 getValidationState,
		 getColor,
		 fullName,
		 // UI
		 editorOption,
		 resolveAvatarVariant,

		 // Filter/Formatter
		 avatarText,
	  }
   },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/quill.scss';
</style>

<style lang="scss"
	   scoped>
@import '~@core/scss/base/bootstrap-extended/include';


.assignee-selector {
   ::v-deep .vs__dropdown-toggle {
	  padding-left: 0;
   }
}


</style>
